/**
 * Created by LiuXinai on 19/06/2017.
 */

// @i18n-ignore-all
export default [
  {
    id: '@mybi',
    path: '/mybi',
    ref: '/',
    onload: () => import('./bi-view'),

    getJwt: (...args) => {
      return import('./bi-action').then(({ getJwt }) => getJwt(...args));
    },
  },
  {
    path: '/mc-mybi',
    ref: '/',
    onload: () => import('./bi-view'),

    getJwt: (...args) => {
      return import('./bi-action').then(({ getJwt }) => getJwt(...args));
    },
  },
  {
    point: '@@menus',
    onload: () => [
      {
        id: 'mybi',
        pId: 'reports-new',
        powers: ['CustomReport'],
        permissions: ['CUSTOM_REPORT_VIEW'],
        weight: 1,
        label: '高级报表',
        actived: true,
        href: '/mybi',
        icon: 'myreport',
      },
    ],
  },
];
